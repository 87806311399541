import React from 'react';
import './Navigationbar.css';
import logo from './tui-logo.svg';


/**
 * @typedef PropertiesHash
 * @type {object}
 * @property {string} active - the current active page.
 * @property {App.changePage} changePage - callback when button clicked.
 */

/**
 *
 * @param {PropertiesHash} props
 * @returns {*}
 * @constructor
 */
function Navigationbar(props) {
    console.log(props);
    return (
        <div className="nav-bar">
            <div className="app-name" onClick={() => props.changePage("DOCUMENTATION")}>
                <img src={logo} alt="TUI-Logo" className="logo"></img>
                <div className="app-name-item">Content.API</div>
            </div>
            <div className="nav-buttons">
                <div className={props.active === "DOCUMENTATION" ? "nav-button active" : "nav-button"} onClick={() => props.changePage("DOCUMENTATION")}>Documentation</div>
                <div className={props.active === "CHANGELOG" ? "nav-button active" : "nav-button"} onClick={() => props.changePage("CHANGELOG")}>Changelog</div>
                <div className={props.active === "PLAYGROUND" ? "nav-button active" : "nav-button"} onClick={() => props.changePage("PLAYGROUND")}>Playground</div>
                <div className={props.active === "GLOBALTYPES" ? "nav-button active" : "nav-button"} onClick={() => props.changePage("GLOBALTYPES")}>GlobalTypes</div>
                <div className={props.active === "CDN" ? "nav-button active" : "nav-button"} onClick={() => props.changePage("CDN")}>Picture.CDN</div>
            </div>
        </div>
    );
}

export default Navigationbar;
