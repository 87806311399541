import {Component} from "react";
import * as React from "react";
import {GlobalTypeCard} from "./model/global-type-card.interface";
import {GlobalTypeItem} from "./model/global-type-item.interface";
import {Accordion, Card} from "react-bootstrap";


type MyProps = {
    globalTypeCards: Array<GlobalTypeCard>
};

export class GlobalTypeAccordion extends Component<MyProps, {}> {

    render() {
        if (this.props && this.props.globalTypeCards && this.props.globalTypeCards.length > 0) {
            return (
                <Accordion>
                    {this.props.globalTypeCards.map((gtCard, index) => {
                        return (
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                                    {gtCard.header}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={index.toString()}>
                                    <Card.Body>
                                        <div>
                                            {this.renderGlobalTypes(gtCard.globalTypes)}
                                            {this.renderSubTypes(gtCard.subTypes)}
                                            {this.renderAdditionalTypes(gtCard.additionalTypes)}
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>
                        )
                    })}
                </Accordion>
            )
        } else {
            return null;
        }
    }

    renderGlobalTypeItem(item: GlobalTypeItem, index: number) {
        return (
            <tr>
                <td>{index + 1}</td>
                <td>{item.code}</td>
                <td>{item.en_label}</td>
                <td>{item.en_description}</td>
            </tr>
        )
    }

    renderGlobalTypes(globalTypes: Array<GlobalTypeItem>) {
        if (!globalTypes || globalTypes.length === 0) {
            return null;
        }
        return (
            <div>
                <h4>GlobalTypes</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>GlobalType</th>
                            <th>Label</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                    {globalTypes.map((d, idx) => {
                        return this.renderGlobalTypeItem(d, idx);
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    renderSubTypes(subTypes: Array<GlobalTypeItem>) {
        if (!subTypes || subTypes.length === 0) {
            return null;
        }
        return (
            <div>
                <h4>SubTypes</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>SubType</th>
                            <th>Label</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                    {subTypes.map((d, idx) => {
                        return this.renderGlobalTypeItem(d, idx);
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    renderAdditionalTypes(additionalTypes: Array<GlobalTypeItem>) {
        if (!additionalTypes || additionalTypes.length === 0) {
            return null;
        }
        return (
            <div>
                <h4>AdditionalTypes</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>AdditionalType</th>
                            <th>Label</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                    {additionalTypes.map((d, idx) => {
                        return this.renderGlobalTypeItem(d, idx);
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

}
