import {Component} from "react";
import * as React from "react";
import {Accordion, Card} from "react-bootstrap";
import {TuiType} from "./model/tui-type.interface";

type MyState = {
    tuiTypes: Array<TuiType>,
    tuiHotelChains: Array<TuiType>
};

type MyProps = {
    tuiTypes: Array<TuiType>
};

export class TuiTypesAccordion extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);

        let filteredTuiTypes = this.props.tuiTypes.filter((tuiType) => tuiType.desc).filter((tuiType) => !tuiType.globalType.includes('GT12'));
        let hotelChains = this.props.tuiTypes.filter((tuiType) => tuiType.globalType.includes('GT12'));

        this.state = {
            tuiTypes: filteredTuiTypes,
            tuiHotelChains: hotelChains
        }
    }

    render() {
        if (this.state && this.state.tuiTypes && this.state.tuiTypes.length > 0) {
            return (
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            General TUI Types
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>TuiType</th>
                                                <th>Related Global-/Sub-/Additional-Type</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.tuiTypes.map((tuiType, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{tuiType.code}</td>
                                                    <td>{[tuiType.globalType, tuiType.subType, tuiType.additionalType].filter(Boolean).join('/')}</td>
                                                    <td>{tuiType.desc ? tuiType.desc : tuiType.germanDesc}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            TUI Hotel Chains
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <div>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>TuiType</th>
                                            <th>Related Global-/Sub-/Additional-Type</th>
                                            <th>Description</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.tuiHotelChains.map((tuiType, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{tuiType.code}</td>
                                                    <td>{[tuiType.globalType, tuiType.subType, tuiType.additionalType].filter(Boolean).join('/')}</td>
                                                    <td>{tuiType.desc ? tuiType.desc : tuiType.germanDesc}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            )
        } else {
            return null;
        }
    }
}
