import {GlobalTypeCard} from "../model/global-type-card.interface";
import {GlobalTypeItem} from "../model/global-type-item.interface";

const Papa = require("papaparse/papaparse.min.js");

export async function loadGlobalTypes(): Promise<Array<GlobalTypeCard>> {

    let csvFiles: Array<any> = [];
    csvFiles.push(require("../resources/GT02_GT_Accommodation-Types.csv"));
    csvFiles.push(require("../resources/GT02_ST_Accommodation-Types.csv"));
    csvFiles.push(require("../resources/GT02_AT_Accommodation-Types.csv"));
    csvFiles.push(require("../resources/GT03_GT_Accommodation-Attributes.csv"));
    csvFiles.push(require("../resources/GT03_ST_Accommodation-Attributes.csv"));
    csvFiles.push(require("../resources/GT03_AT_Accommodation-Attributes.csv"));
    csvFiles.push(require("../resources/GT04_GT_Room-Types.csv"));
    csvFiles.push(require("../resources/GT04_ST_Room-Types.csv"));
    csvFiles.push(require("../resources/GT04_AT_Room-Types.csv"));
    csvFiles.push(require("../resources/GT05_GT_Room-Attributes.csv"));
    csvFiles.push(require("../resources/GT05_ST_Room-Attributes.csv"));
    csvFiles.push(require("../resources/GT05_AT_Room-Attributes.csv"));
    csvFiles.push(require("../resources/GT06_GT_Boards.csv"));
    csvFiles.push(require("../resources/GT06_ST_Boards.csv"));
    csvFiles.push(require("../resources/GT08_GT_Additional-Services.csv"));
    csvFiles.push(require("../resources/GT08_ST_Additional-Services.csv"));
    csvFiles.push(require("../resources/GT08_AT_Additional-Services.csv"));
    csvFiles.push(require("../resources/GT13_GT_Room-Locations.csv"));
    csvFiles.push(require("../resources/GT13_AT_Room-Locations.csv"));
    csvFiles.push(require("../resources/GT18_GT_Payment-Types.csv"));
    csvFiles.push(require("../resources/GT18_ST_Payment-Types.csv"));


    let groupedByGlobalTypeCode: Map<string, string[]> = groupByGlobalType(csvFiles);
    return loadCsvData(groupedByGlobalTypeCode);
}

function groupByGlobalType(list: Array<any>): Map<string, string[]> {
    const map = new Map();
    list.forEach((item: string) => {
        const gtCode = item.replace('./static/media/', '').replace('/static/media/', '').split('.')[0].split('_')[0];
        const collection = map.get(gtCode);
        if (!collection) {
            map.set(gtCode, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

async function loadCsvData(groupedByGlobalTypeCode: Map<string, string[]>): Promise<Array<GlobalTypeCard>> {
    let result: Array<GlobalTypeCard> = [];
    for (const [key, value] of groupedByGlobalTypeCode) {
        let csvFiles = value;
        if (csvFiles && csvFiles.length > 0) {
            let header = key + " - " + csvFiles[0].replace('./static/media/', '').replace('/static/media/', '').split('.')[0].split('_')[2].split("-").join(" ");
            let globalTypes: Array<GlobalTypeItem> = [];
            let subTypes: Array<GlobalTypeItem> = [];
            let additionalTypes: Array<GlobalTypeItem> = [];

            for (const csvFile of csvFiles) {
                let type = csvFile.replace('./static/media/', '').replace('/static/media/', '').split('.')[0].split('_')[1];
                let csvParseResult: any = await parseFile(csvFile);
                if (type === 'GT') {
                    globalTypes.push(...csvParseResult);
                }
                if (type === 'ST') {
                    subTypes.push(...csvParseResult);
                }
                if (type === 'AT') {
                    additionalTypes.push(...csvParseResult);
                }
            }

            let gtCard: GlobalTypeCard = {
                header: header,
                globalTypes: globalTypes,
                subTypes: subTypes,
                additionalTypes: additionalTypes
            }
            result.push(gtCard);
        }
    }
    return result;
}

async function parseFile(rawFile: string) {
    return new Promise(resolve => {
        Papa.parse(rawFile, {
            header: true,
            download: true,
            skipEmptyLines: true,
            delimiter: ";",
            complete: (results: any) => {
                resolve(results.data);
            }
        });
    });
}

// eslint-disable-next-line
async function asyncForEach(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}