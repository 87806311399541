import React, { Component } from "react";
import "./Loading.css";

export class Loading extends Component<{}, {}> {

    render() {
        return (
            <div className="loader">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                </div>
            </div>
        )
    }

}
