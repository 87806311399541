import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import React from "react";
import DocumentationRest from "./DocumentationRest";
import DocumentationGraphQL from "./DocumentationGraphQL";
import "./Documentation.css";

function Documentation() {

    return (
        <div className="documentation-tabs">
            <Tabs defaultActiveKey="graphql" id="uncontrolled-tab-example">
                <Tab eventKey="graphql" title="GraphQL">
                    <DocumentationGraphQL />
                </Tab>
                <Tab eventKey="rest" title="REST">
                    <DocumentationRest />
                </Tab>
            </Tabs>
        </div>
    );
}

export default Documentation;
