import React from 'react';
import './ContentPlayground.css';
import { Provider } from "react-redux";
import { Playground, store } from "graphql-playground-react";

function ContentPlayground() {
    return (
        <div className="content-playground">
            If this embedded playground should not work properly (e.g. not able to retrieve the response) please use the real playground: <a href="https://prod.api.tui/content">https://prod.api.tui/content</a>
            <Provider store={store}>
                <Playground endpoint="https://prod.api.tui/content">https://prod.api.tui/content</Playground>
            </Provider>
        </div>
    );
}

export default ContentPlayground;
