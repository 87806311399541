import React from "react";
import "./Footer.css";

function Footer(props) {
    return (
        <div className="footer">
            <div className="copyright">Copyright TUI</div>
            <div className="nav-buttons">
                <button className="nav-item" onClick={() => props.changePage("IMPRESSUM")}>Impressum</button>
            </div>
        </div>
    );
}

export default Footer;
