import React from 'react';
import "./CdnDocumentation.css";

function CdnDocumentation() {

    return (
        <div className="documentation">
            <div className="documentation-content">
                <div className="documentation-item">
                    <h1>Picture.CDN</h1>
                    <p>The picture URL's from the Content.API contains the highest available resolution for web content (1600x1200). Due to performance reasons those pictures could/should be scaled. Therefore we added dynamic resizing capabilities for the content stack (including the Picture.CDN with caching support).</p>
                    <p>The Picture.CDN is currently hosted by Instart (this may change in the future in favor to AWS CloudFront) and could be reached via <a href="https://cloud.tui.com/pics/">https://cloud.tui.com/pics/</a> An example url to scale down to 800x600 is <a href="https://cloud.tui.com/pics/hotels/resize:fit:800:600/aHR0cHM6Ly9waWNzLnR1aS5jb20vcGljcy9waWNzMTYwMHgxMjAwL3R1aS9kL2RiYjMxNTUxLWViNDEtNGQzNy1hMzJmLWU1NmU0ZjY2Njc0ZS5qcGc="  >https://cloud.tui.com/pics/hotels/resize:fit:800:600/aHR0cHM6Ly9waWNzLnR1aS5jb20vcGljcy9waWNzMTYwMHgxMjAwL3R1aS9kL2RiYjMxNTUxLWViNDEtNGQzNy1hMzJmLWU1NmU0ZjY2Njc0ZS5qcGc=</a></p>
                    <p>The image-resizer behind the CDN is mainly a configured <a href="https://github.com/imgproxy/imgproxy">https://github.com/imgproxy/imgproxy</a>. So check this url for documentation infos how to create the resize urls.</p>
                    <p className="important-wrapper"><b>Note: </b>The maximum resolution of the source image is 16.8MP as you can see in the <a href="https://docs.imgproxy.net/#/configuration?id=security">img-proxy documentation</a></p>
                </div>
            </div>
        </div>
    );
}

export default CdnDocumentation;
