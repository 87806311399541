import {TuiType} from "../model/tui-type.interface";

const Papa = require("papaparse/papaparse.min.js");

export async function loadTuiTypes(): Promise<Array<TuiType>> {
    let tuiTypeCsvFile = require("../resources/TuiTypes.csv");

    let parseTuiTypes: Array<any> = await parseFile(tuiTypeCsvFile);
    return parseTuiTypes.map((originalTuiType: any) => {
        return {
            code: originalTuiType['#tuiType#'],
            globalType: originalTuiType['#globalType#'],
            subType: originalTuiType['#subType#'],
            additionalType: originalTuiType['#additionalType#'],
            desc: originalTuiType['#english#'],
            germanDesc: originalTuiType['#german#']
        } as TuiType
    });
}

async function parseFile (rawFile: string): Promise<Array<any>> {
    return new Promise(resolve => {
        Papa.parse(rawFile, {
            header: true,
            download: true,
            skipEmptyLines: true,
            delimiter: ";",
            complete: (results: any) => {
                resolve(results.data);
            }
        });
    });
};
