import {Component} from "react";
import * as React from "react";
import {TuiType} from "./model/tui-type.interface";
import {GlobalTypeCard} from "./model/global-type-card.interface";
import {GlobalTypeItem} from "./model/global-type-item.interface";

type MyState = {
    globalTypes: Array<GlobalTypeItem>,
    search: string,
    results: Array<GlobalTypeItem>
};

type MyProps = {
    globalTypeCards: Array<GlobalTypeCard>,
    tuiTypes: Array<TuiType>
};

export class GlobalTypeSearch extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            globalTypes: this.loadGlobalTypes(),
            results: [],
            search: ''
        }
        this.handleSearchInput = this.handleSearchInput.bind(this);
    }

    render() {
        return (
            <div className="global-type-search-component">
                <div className="search-bar">
                    <input type="text" className="form-control form-control-lg" onChange={this.handleSearchInput} placeholder="Search, e.g. 'GT03-BABO'..." />
                </div>
                {this.renderSearchResults()}
            </div>
        )
    }

    renderSearchResults() {
        if (this.state && this.state.search && this.state.results && this.state.results.length > 0) {
            return (
                <div className="search-results">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Code</th>
                            <th>Label</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.results.slice(0, 10).map((d, idx) => {
                            return this.renderSearchResult(d, idx);
                        })}
                        </tbody>
                    </table>

                </div>
            )
        }
        if (this.state && this.state.search && this.state.results && this.state.results.length === 0) {
            return (
                <div className="search-results">
                    <p>No results found for: {this.state.search}</p>
                </div>
            )
        }
        return null;
    }

    renderSearchResult(globalType: GlobalTypeItem, index: number) {
        return (
            <tr>
                <td>{index + 1}</td>
                <td>{globalType.code}</td>
                <td>{globalType.en_label}</td>
                <td>{globalType.en_description}</td>
            </tr>
        )
    }

    loadGlobalTypes(): Array<GlobalTypeItem> {
        let result: Array<GlobalTypeItem> = [];
        let tuiTypes: Array<TuiType> = this.props.tuiTypes;
        let mappedTuiTypes: Array<GlobalTypeItem> = tuiTypes.map((tt) => {
            return {
                code: tt.code,
                en_description: tt.desc ? tt.desc : tt.germanDesc,
                en_label: '-'
            } as GlobalTypeItem
        });
        result.push(...mappedTuiTypes);
        let globalTypeCards: Array<GlobalTypeCard> = this.props.globalTypeCards;
        for (const card of globalTypeCards) {
            result.push(...card.globalTypes);
            result.push(...card.subTypes);
            result.push(...card.additionalTypes);
        }
        return result;
    }

    handleSearchInput(e: any) {
        let filtered: Array<GlobalTypeItem> = [];

        if (e.target.value !== "") {
            let searchGts: Array<string> = e.target.value.split('/').map((gt: string) => gt.toLowerCase());
            let useFullList: boolean = e.target.value.length >= 2 ? e.target.value.charAt(e.target.value.length - 2) === '/' : false;
            if (this.state.search.length < e.target.value.length && this.state.results.length > 0 && !useFullList) {
                filtered = this.state.results.filter((gt) => this.filterGlobalType(gt, searchGts));
            } else {
                filtered = this.state.globalTypes.filter((gt) => this.filterGlobalType(gt, searchGts));
            }
            if (searchGts.length > 1) {
                filtered = filtered.sort((a: GlobalTypeItem, b: GlobalTypeItem) => {
                    return this.getIndex(searchGts, a.code) - this.getIndex(searchGts, b.code);
                });
            }

        }

        this.setState({
            search: e.target.value,
            results: filtered
        });
    }

    filterGlobalType(globalType: GlobalTypeItem, searchedCodes: Array<string>): boolean {
        for (const searchedCode of searchedCodes) {
            if (searchedCode && ((globalType.code.toLowerCase().includes(searchedCode.toLowerCase()))
                || globalType.en_label.toLowerCase().includes(searchedCode.toLowerCase()))) {
                return true;
            }
        }
        return false;
    }

    getIndex(sorting: Array<string>, gt: string): number {
        let index = sorting.indexOf(gt.toLowerCase());
        if (index === -1) {
            return Infinity;
        }
        return index;
    };

}
