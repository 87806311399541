import React from 'react';
import "./DocumentationGraphQL.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function DocumentationRest() {

    return (
        <div className="documentation">
            <div className="documentation-content">

                <div className="documentation-item">
                    <h1>Content.API by REST</h1>
                    <p>With the Content.API by REST we provide an alternative and well-known way for querying hotel-content. You can find the Swagger-Documentation <a href="https://test.api.tui/content/swagger/"  >here</a></p>
                    <p className="list-intro">We provide two environments (prod & test) for the REST Content.API with following endpoints:</p>
                    <ul>
                        <li>Prod: https://prod.api.tui/content/hotels</li>
                        <li>Test: https://test.api.tui/content/hotels</li>
                    </ul>
                </div>

                <div className="documentation-item">
                    <h1>Available Endpoints</h1>
                    <p className="list-intro">At the moment, the Content.API by REST provides three GET endpoints:</p>
                    <ul>
                        <li><span className="prominent">hotels/&#123;code&#125;</span></li>
                        <li><span className="prominent">hotels?code=&#123;code&#125;</span></li>
                        <li><span className="prominent">hotels?giata-id=&#123;giata-id&#125;</span></li>
                        <li><span className="prominent">hotels?cmd-id=&#123;cmd-id&#125;</span></li>
                        <li>deprecated <span className="prominent">hotel-by-code/&#123;code&#125;</span></li>
                        <li>deprecated <span className="prominent">hotel-by-giata-id/&#123;giata-id&#125;</span></li>
                        <li>deprecated <span className="prominent">hotel-by-cmd-id/&#123;cmd-id&#125;</span></li>
                    </ul>
                </div>

                <div className="documentation-item">
                    <h1>Query-Params</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Example</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>locale</td>
                                <td>Enum</td>
                                <td>Defines the locale of the content</td>
                                <td>de_DE</td>
                            </tr>
                            <tr>
                                <td>date</td>
                                <td>String</td>
                                <td>Defines the date for which the content should be queried. A hotel might have different content for different time periods (e.g. when the hotel changes its name or different content for winter and summer etc.). Pattern "yyyy-mm-dd"</td>
                                <td>2020-04-16</td>
                            </tr>
                            <tr>
                                <td>data</td>
                                <td>Boolean</td>
                                <td>Whether or not the response should contain hotel-data (e.g. address, name, contact-infos etc.)</td>
                                <td>true,false</td>
                            </tr>
                            <tr>
                                <td>details</td>
                                <td>Boolean</td>
                                <td>Whether or not the response should contain hotel-details (e.g. images, room-infos, textual descriptions etc.)</td>
                                <td>true,false</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="documentation-item">
                    <h1>Examples</h1>
                    <ul>
                        <li><a href="https://test.api.tui/content/hotels/FUE21021">https://test.api.tui/content/hotels/FUE21021</a></li>
                        <li><a href="https://test.api.tui/content/hotels/FUE21021?locale=de_DE&details=true">https://test.api.tui/content/hotels/FUE21021?locale=de_DE&details=true</a></li>
                        <li><a href="https://test.api.tui/content/hotels/FUE21021?data=false&details=true">https://test.api.tui/content/hotels/FUE21021?data=false&details=true</a></li>
                        <li><a href="https://test.api.tui/content/hotels?code=FUE21021">https://test.api.tui/content/hotels?code=FUE21021</a></li>
                        <li><a href="https://test.api.tui/content/hotels?cmd-id=AC8007574">https://test.api.tui/content/hotels?cmd-id=AC8007574</a></li>
                        <li><a href="https://test.api.tui/content/hotels?giata-id=2589">https://test.api.tui/content/hotels?giata-id=2589</a></li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default DocumentationRest;
