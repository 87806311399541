import React, { Component } from 'react';
import "./GlobalTypes.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {GlobalTypeAccordion} from "./GlobalTypeAccordion";
import {TuiTypesAccordion} from "./TuiTypesAccordion";
import {GlobalTypeSearch} from "./GlobalTypeSearch";
import {loadGlobalTypes} from "./service/global-types.service";
import {GlobalTypeCard} from "./model/global-type-card.interface";
import {TuiType} from "./model/tui-type.interface";
import {loadTuiTypes} from "./service/tui-types.service";
import {Loading} from "./Loading";

type MyState = {
    globalTypeCards: Array<GlobalTypeCard>,
    loadingGlobalTypes: boolean,
    tuiTypes: Array<TuiType>,
    loadingTuiTypes: boolean
};

export class GlobalTypes extends Component<{}, MyState> {

    constructor(props: any) {
        super(props);
        this.state = {
            globalTypeCards: [],
            tuiTypes: [],
            loadingGlobalTypes: true,
            loadingTuiTypes: true
        }
    }

    componentDidMount(): void {
        loadGlobalTypes().then((globalTypeCards: Array<GlobalTypeCard>) => {
            this.setState({
                globalTypeCards: globalTypeCards,
                loadingGlobalTypes: false
            });
        });
        loadTuiTypes().then((tuiTypes: Array<TuiType>) => {
           this.setState({
               tuiTypes: tuiTypes,
               loadingTuiTypes: false
           })
        });
    }

    renderTuiTypeAccordion() {
        if (this.state.loadingTuiTypes) {
            return (<Loading/>)
        }
        return (<TuiTypesAccordion tuiTypes={this.state.tuiTypes}/>)
    }

    renderGlobalTypeAccordion() {
        if (this.state.loadingGlobalTypes) {
            return (<Loading/>)
        }
        return (<GlobalTypeAccordion globalTypeCards={this.state.globalTypeCards}/>)
    }

    renderGlobalTypeSearch() {
        if (this.state && !this.state.loadingGlobalTypes && !this.state.loadingTuiTypes) {
            return (<GlobalTypeSearch tuiTypes={this.state.tuiTypes} globalTypeCards={this.state.globalTypeCards}/>)
        }
        return (<Loading/>)
    }

    render() {
        return (
            <div className="global-types">
                <div className="global-types-content">
                    <div className="global-types-item">
                        <h1>Search for GlobalTypes</h1>
                        <p>Here you can search for a GlobalType to find its description. The search shows a maximum of 10 results. Please keep in mind that our
                            dataset might be out of date or incomplete. For up-to-date data we recommend using the
                            GlobalType Center.</p>
                        {this.renderGlobalTypeSearch()}
                    </div>

                    <div className="global-types-item">
                        <h1>About GlobalTypes <span className="reference">Text copied from <a
                            href="https://globaltypecenter.de/index.php?language=en">globaltypecenter.de</a></span></h1>
                        <p>GlobalTypes® describe uniform descriptions of touristic attributes which are uniquely coded.
                            Each feature is distinct and coded respectively. Coding itself is carried out by a
                            standardized principle but may vary in length depending on the respective GlobalType
                            category.</p>
                    </div>

                    <div className="global-types-item">
                        <h1>GlobalType hierarchy <span className="reference">Text copied from <a
                            href="https://globaltypecenter.de/index.php?language=en">globaltypecenter.de</a></span></h1>
                        <p>Three hierarchy levels exist to choose from, the highest class are GlobalTypes® to which
                            SubTypes and AdditionalTypes may be allocated. GlobalTypes® can also be applied stand alone.
                            If a GlobalType is to be specified further, a SubType is assigned. AdditionalTypes are
                            additional features which can be allocated to both GlobalTypes® and SubTypes (e.g. at cost,
                            free of charge etc.). In contrast to GlobalTypes®, SubTypes and AdditionalTypes alone are
                            not meaningful.</p>
                    </div>

                    <div className="global-types-item">
                        <h1>GlobalType Center <span className="reference">Text copied from <a
                            href="https://globaltypecenter.de/index.php?language=en">globaltypecenter.de</a></span></h1>
                        <p>The GlobalType Center is a platform to view and download up-do-date GlobalTypes®. You can
                            find a link to the GlobalType Center below</p>
                        <p><a href="https://globaltypecenter.de/index.php?language=en">https://globaltypecenter.de</a>
                        </p>
                    </div>

                    <div className="global-types-item">
                        <h1>GlobalTypes overview</h1>
                        <p>In this section you will find an overview of most of the available GlobalTypes and its
                            SubTypes and AdditionalTypes. For a complete and up-to-date overview please visit the
                            GlobalType Center.</p>
                        {this.renderGlobalTypeAccordion()}
                    </div>

                    <div className="global-types-item">
                        <h1>TuiTypes overview</h1>
                        <p>TuiTypes are an expansion of the official DRV GlobalTypes and describe custom TUI specific attributes.</p>
                        {this.renderTuiTypeAccordion()}
                    </div>

                    <div className="global-types-item">
                        <h1>Examples</h1>
                        <p className="list-intro">Here are some examples of complex GlobalTypes:</p>
                        <ul>
                            <li><span className="prominent">GT03-DIAI/ST03-D50KM</span> - The distance to airport is less than 50 km</li>
                            <li><span className="prominent">GT03-POOL/ST03-WHJA/AT03-FRCH</span> - A pool that is a whirlpool that is free of charge</li>
                            <li><span className="prominent">GT04-RO/ST04-DO/AT04-BR01</span> - A room that is a double room that has 1 bedroom</li>
                        </ul>
                    </div>

                </div>
            </div>
        )
    }
}
