import React from 'react';
import './App.css';
import ContentPlayground from "./ContentPlayground";
import Changelog from "./Changelog";
import Footer from "./Footer";
import Navigationbar from "./Navigationbar";
import Impressum from "./Impressum";
import Documentation from "./Documentation";
import {GlobalTypes} from "./GlobalTypes";
import CdnDocumentation from "./CdnDocumentation";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.changePage = this.changePage.bind(this);
        this.state = {
            active: 'DOCUMENTATION'
        };
    }

    render() {
        return (
            <div className="App">
                <div className="app-header">
                    <Navigationbar active={this.state.active} changePage={this.changePage} />
                </div>
                { this.state.active === "DOCUMENTATION" && <Documentation /> }
                { this.state.active === "PLAYGROUND" && <ContentPlayground /> }
                { this.state.active === "CHANGELOG" && <Changelog /> }
                { this.state.active === "IMPRESSUM" && <Impressum /> }
                { this.state.active === "GLOBALTYPES" && <GlobalTypes /> }
                { this.state.active === "CDN" && <CdnDocumentation /> }
                <div className="app-footer">
                    <Footer active={this.state.active} changePage={this.changePage} />
                </div>
            </div>
        );
    };

    changePage(page) {
        this.setState({active: page});
    }

}



export default App;
