import React from 'react';
import explore from './content-api-graphql.gif';
import autocomplete from "./graphql-autocomplete.png";
import documentation from "./graphql-documentation.png";
import schema from "./graphql-schema.png";
import "./DocumentationGraphQL.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

const javascriptSnippet =
    `let queryData = {
  query: "{\n  getHotelByCode(code: $code) {\n    code\n    giataId\n    name\n    details {\n      images {\n        title\n        url\n      }\n    }\n  }\n}",
  variables: {code: "FUE21021"}
}

fetch(url, {
  method: "POST",
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json"
  },
  redirect: "follow",
  referrer: "no-referrer",
  body: JSON.stringify(queryData),
}).then(response => response.json());
`;

const javaSnippet =
    `public ResponseEntity<ContentApiResponse> getHotelByCode(final String productCode) {
  final HttpHeaders headers = new HttpHeaders();
  headers.setContentType(MediaType.APPLICATION_JSON_UTF8);

  final String getHotelByCodeRequest =
    "{\\"query\\":\\"{\\\\n  getHotelByCode(code: \\\\\\"" + productCode
                          + "\\\\\\") {\\\\n    code\\\\n    name\\\\n    phone\\\\n    fax\\\\n    email\\\\n    address {\\\\n      street\\\\n      postalCode\\\\n      city\\\\n      country\\\\n      region\\\\n    }\\\\n  }\\\\n}\\\\n\\"}";

  final HttpEntity<String> entity = new HttpEntity<>(getHotelByCodeRequest, headers);
  return contentApiRestTemplate.exchange("/api", HttpMethod.POST, entity, ContentApiResponse.class);
}`;

function DocumentationGraphQL() {


    return (
        <div className="documentation">
            <div className="documentation-content">
                <div className="documentation-item">
                    <h1><img src="new.png" style={{ width: "80px" }} alt=""></img> Accommodation raw attributes</h1>
                    <p>We now do also provide certain raw attributes in our Content.API to enable our consumers to fulfill their requirements regarding the display of accommodation content on their websites. Please find raw attributes available for the following categories:
                      <ul>
                          <li>Check-in/ check-out time</li>
                          <li>Room attributes</li>
                          <li>Location details</li>
                          <li>Location distances</li>
                      </ul>
                    </p>
                </div>
                <div className="documentation-item">
                    <h1>Adding Global types</h1>
                    <p>There are Global Types (e.g. for the partner programme, awards, target groups, sustainability etc.) added to the Global Types section. (e.g. GT03_TUI-G1068 = TUI Partner Hotel LMP, GT03_TUI-G1091 = TUI Partner Hotel MP, GT03_TUI-G1066 = TOP100 TUI Hotels...)</p> 
                    <p>Also we provide a new global type from sustainability section: Charging station for electric cars GT03_CHST/ST03-CHEL</p>
                </div>
                <div className="documentation-item">
                    <h1>Sustainability Information for Hotels!!!</h1>
                    <p>To support the sales systems with sustainability informations the Content.API got enhanced with detailed sustainability facts on hotel level. The information is gathered via destimo integration and stored in new developed content API stack using also an AWS DynamoDB. Consumers of the Content.API are able to query that sustainability-info as part of the normal hotel entity.</p>
                    <p>Check out the updated GraphQL schema for further details!</p>
                </div>
                <div className="documentation-item">
                    <h1>Country Information including Entry-Requirements</h1>
                    <p>The content.API got enhanced with country information, including entry-requirements (but at the moment for germans, austrians and swiss and in german locale only).</p>
                    <p>Check out the updated GraphQL schema for further details!</p>
                </div>

                <div className="documentation-item">
                    <h1>Content.API by GraphQL <span className="reference">Text copied from <a href="https://developer.github.com/v4/">github.com</a></span></h1>
                    <p>GraphQL is a query language for APIs that allows clients to request exactly the data they need, making it possible to get all required data in a limited number of requests.</p>
                    <p className="list-intro">The GraphQL data query language is:</p>
                    <ul>
                        <li>A specification - The spec determines the validity of the schema on the API server. The schema determines the validity of client calls.</li>
                        <li>Strongly typed - The schema defines an API's type system and all object relationships.</li>
                        <li>Introspective - A client can query the schema for details about the schema.</li>
                    </ul>
                </div>

                <div className="documentation-item">
                    <h1>Content.API GraphQL Endpoints</h1>
                    <p className="list-intro">We provide two environments (prod & test) for the Content.API with following endpoints:</p>
                    <ul>
                        <li>Prod: <a href="https://prod.api.tui/content">https://prod.api.tui/content</a></li>
                        <li>Test: <a href="https://test.api.tui/content">https://test.api.tui/content</a></li>
                    </ul>
                </div>

                <div className="documentation-item">
                    <h1>Why Content.API is using GraphQL <span className="reference">Text copied from <a href="https://developer.github.com/v4/"  >github.com</a></span></h1>
                    <p>We decided to use GraphQL because it offers significantly more flexibility for our integrators. The ability to define precisely the data you want - and only the data you want - is a powerful advantage over a REST API. GraphQL lets you replace multiple REST requests with a single call to fetch the data you specify.</p>
                </div>

                <div className="documentation-item">
                    <h1>What kind of data the Content.API provides</h1>
                    <p>The (federated) Content.API contains hotel information. You can query for images, address- & contact-data, room- & sustainability-information and many more. Have a look at the next section to learn how to explore the Content.API.</p>

                    <h2>Explore Content.API</h2>
                    <p>In order to explore the Content.API, you can use the interactive <a href="https://prod.api.tui/content"  >Content.API-Playground</a>. It supports code-completion, debugging as-you-type, giving hints and pointing out errors. Paste the very simple query from below and manipulate it to your liking. To actually execute the query, press the play button. If the syntax of the query is not correct, the API editor may silently fail. So if something does not work as expected, please double check the query syntax and the URL.</p>

                    <div className="code-wrapper">
                        <pre className="pre-code">
                            {`{
  getHotelByCode(code: "FUE21021") {
    code
    giataId
    cmdId
    name
    coordinates {
      latitude
      longitude
    }
  }
}`}
                        </pre>
                    </div>
                    <br /><p className="important-wrapper"><b>Note: </b>Make sure to append /content to the URL displayed in the query editor’s own address bar. It must hold the same URL as the browser’s address bar. Otherwise you will receive the error "Unexpected token in JSON at position 0"</p>
                    <img src={explore} alt="Explore Content.API" />
                </div>

                <div className="documentation-item">
                    <h1>Content.API queries</h1>
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                getHotelByCode
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve your desired content for the provided product-code.</p>

                                        <h4>Mandatory parameters</h4>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Parameter</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Example</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>code</td>
                                                    <td>String</td>
                                                    <td>product-code of the hotel</td>
                                                    <td>FUE21021</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h4>Optional parameters</h4>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Parameter</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Example</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>locale</td>
                                                    <td>Enum</td>
                                                    <td>Defines the locale of the content</td>
                                                    <td>de_DE</td>
                                                </tr>
                                                <tr>
                                                    <td>date</td>
                                                    <td>String</td>
                                                    <td>A specific date for which you want to have the content</td>
                                                    <td>2019-12-31</td>
                                                </tr>
                                                <tr>
                                                    <td>provider</td>
                                                    <td>Enum</td>
                                                    <td>A specific provider (organizer) for which you want to have the content</td>
                                                    <td>TUI</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getHotelByCode(code: "FUE21021", locale: de_DE, date: "2019-12-31", provider: TUI) {
    code
    giataId
    name
    details {
      images {
        title
        url
      }
    }
  }
}`}
                                            </pre>
                                        </div>

                                        <h4>Full Example</h4>
                                        <p>The Full Example shows you all the possibilities.
                                            As this list is created manually, you better relay on the docs/schema documentation.
                                            Anyway you can just remove the entries you do not need to customize to the request you wish.</p>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getHotelByCode(code: "FUE21021", locale: de_DE, date: "2019-12-31", provider: TUI) {
    language
    code
    giataId
    cmdId
    name
    category
    coordinates {
      latitude, 
      longitude
    }
    metaInfo {
      provider
      source
      lastUpdatedAt
      createdAt
      importerVersion
      validFrom
      validTo
    }
    contact {
      address {
        street
        postalCode
        city
        country
        countryCode
        country3LC
        region
      }
      email
      phone
      fax
    }
    cobrand {
      name
      iconUrl
    }
    awards { 
      title
      iconUrl
    }
    labels {
      title
      desc
    }
    details {
      images {
        category {
          code
          desc
        }
        rating
        title
        url
        season {
          code
        }
      }
      videos {
        category {
          code
          desc
        }
        rating
        title
        url
      }
      infos {
        category {
          code
          desc
        }
        categoryGroup {
          code
          desc
        }
        title 
        desc 
        labelId
      }
      productInfos (tuiPromotion: "0009") {
        category {
          code 
          desc
        }
        categoryGroup{
          code
          desc
        }
        title
        desc
        labelId
      }
      locations{
        code
        value
        desc
      }
      locationDistances{
        type
        name
        distance {
          value
          unit
          direct
        }
        journeyTime {
          value
          unit
        }
      }
      rooms{
        code
        title
        desc
        externalCode
        externalSource
        imageUrls
        globalTypes
        facilities {
          code
          desc
        }
        facilityDesc
        attributes {
          code
          subCode
          desc
        }
      }
    }
    globalTypes
    country {
      alpha3Code
      alpha2Code
      name
      entryRequirements {
        leadIn
        currentSituation
        security
        nature
        travelInformation
        customsDuty
        health
        country
        other
        healthcare
        passportAndVisa
        money
        dutyfree
        disclaimers {
          source
          title
          edition
          link
          type
          description
        }
      }
      coronaOpeningDates {
        foreignOffice
        tourOperator
        additionalInfo
      }
    }
    serviceLevelCategory 
    sustainability{
      community {
        activities
        activitiesLabel
        artists
        artistsLabel
        charity
        charityLabel
        education
        educationLabel
        headline
        info
        infoLabel
        invests
        investsLabel
        medical
        medicalLabel
        tours
        toursLabel
        training
        trainingLabel
      }
      eco {
        bicycleparking
        bicycleparkingLabel
        bicyclerent
        bicyclerentLabel
        ebikerent
        ebikerentLabel
        eggs
        eggsLabel
        green
        greenLabel
        headline
        organic
        organicLabel
        shuttlebus
        shuttlebusLabel
      }
      energy {
        charging
        chargingLabel
        generaterenewable
        generaterenewableLabel
        greenhouse
        greenhouseLabel
        grows
        growsLabel
        guest
        guestLabel
        headline
        insulation
        insulationLabel
        led
        ledLabel
        localfood
        localfoodLabel
        management
        managementLabel
        nominibar
        nominibarLabel
        renewable
        renewableLabel
        sensors
        sensorsLabel
        vegan
        veganLabel
        vegetarian
        vegetarianLabel
        waste
        wasteLabel
        wasteenergy
        wasteenergyLabel
        windows
        windowsLabel
      }
      lastModifiedAt
      other {
        headline
        methods
        methodsLabel
        report
        reportLabel
      }
      waste{
        cosmetics
        cosmeticsLabel
        dispenser
        dispenserLabel
        foodwaste
        foodwasteLabel
        headline
        nochemical
        nochemicalLabel
        noplasticstirrers
        noplasticstirrersLabel
        noplasticstraws
        noplasticstrawsLabel
        nosinglebottle
        nosinglebottleLabel
        nosodabottle
        nosodabottleLabel
        recyclingplan
        recyclingplanLabel
        reusablecups
        reusablecupsLabel
        reusabletableware
        reusabletablewareLabel
        waterrefill
        waterrefillLabel  
      }
      water {
        garden
        gardenLabel
        headline
        laundry
        laundryLabel
        noroomcleaning
        noroomcleaningLabel
        pool
        poolLabel
        showers
        showersLabel
        toilets
        toiletsLabel
        towelreuse
        towelreuseLabel
        wastewater
        wastewaterLabel
      }
    }
  }
}`}
                                            </pre>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                getHotelByGiataId
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve your desired content for the provided giata-id.</p>

                                        <h4>Mandatory parameters</h4>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Parameter</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Example</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>giataId</td>
                                                    <td>String</td>
                                                    <td>The hotels giata-id</td>
                                                    <td>2589</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h4>Optional parameters</h4>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Parameter</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Example</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>locale</td>
                                                    <td>Enum</td>
                                                    <td>Defines the locale of the content</td>
                                                    <td>de_DE</td>
                                                </tr>
                                                <tr>
                                                    <td>date</td>
                                                    <td>String</td>
                                                    <td>A specific date for which you want to have the content</td>
                                                    <td>2019-12-31</td>
                                                </tr>
                                                <tr>
                                                    <td>provider</td>
                                                    <td>Enum</td>
                                                    <td>A specific provider (organizer) for which you want to have the content</td>
                                                    <td>TUI</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getHotelByGiataId(giataId: "2589", locale: de_DE, date: "2019-12-31", provider: TUI) {
    code
    giataId
    name
    details {
      images {
        title
        url
      }
    }
  }
}`}
                                            </pre>
                                        </div>
                                        <p>For a Full Example adapt the example from getHotelByCode above.</p>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                getHotelByCmdId
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve your desired content for the provided cmd-id.</p>

                                        <h4>Mandatory parameters</h4>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Parameter</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Example</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>cmdId</td>
                                                    <td>String</td>
                                                    <td>The hotels CMD-Id</td>
                                                    <td>AC8007574</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h4>Optional parameters</h4>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Parameter</th>
                                                    <th>Type</th>
                                                    <th>Description</th>
                                                    <th>Example</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>locale</td>
                                                    <td>Enum</td>
                                                    <td>Defines the locale of the content</td>
                                                    <td>de_DE</td>
                                                </tr>
                                                <tr>
                                                    <td>date</td>
                                                    <td>String</td>
                                                    <td>A specific date for which you want to have the content</td>
                                                    <td>2019-12-31</td>
                                                </tr>
                                                <tr>
                                                    <td>provider</td>
                                                    <td>Enum</td>
                                                    <td>A specific provider (organizer) for which you want to have the content</td>
                                                    <td>TUI</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getHotelByCmdId(cmdId: "AC8007574", locale: de_DE, date: "2019-12-31", provider: TUI) {
    code
    giataId
    name
    details {
      images {
        title
        url
      }
    }
  }
}`}
                                            </pre>
                                        </div>
                                        <p>For a Full Example adapt the example from getHotelByCode above.</p>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                getHotelsByCodes
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve multiple hotels via product-codes. Refer to getHotelByCode for further details as well.</p>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getHotelsByCodes(codes: ["LPA41020", "FUE21021"]) {
    code
    name
  }
}`}
                                            </pre>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                getHotelsByGiataIds
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve multiple hotels via giata-ids. Refer to getHotelByGiataId for further details as well.</p>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getHotelsByGiataIds(giataIds: ["2589","20440"]) {
    giataId
    name
  }
}`}
                                            </pre>
                                        </div>
                                        <p>For a Full Example adapt the example from getHotelByCode above.</p>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                                getHotelsByCmdIds
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve multiple hotels via cmd-ids. Refer to getHotelByCmdId for further details as well.</p>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getHotelsByCmdIds(cmdIds: ["AC8007574","AC13808531"]) {
    giataId
    name
  }
}`}
                                            </pre>
                                        </div>
                                        <p>For a Full Example adapt the example from getHotelByCode above.</p>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="6">
                                getSustainabilityByCmdId
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    <div>
                                        <p>As you can get the same information over getHotelBy* as well, this query is kind of redundant. Anyway, you can query your desired content for the provided cmd-id to get sustainability information.</p>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getSustainabilityByCmdId(cmdId: "AC12040041") {
    name
    sustainability{
      community {
        activities
        activitiesLabel
        artists
        artistsLabel
        charity
        charityLabel
        education
        educationLabel
        headline
        info
        infoLabel
        invests
        investsLabel
        medical
        medicalLabel
        tours
        toursLabel
        training
        trainingLabel
      }
      eco {
        bicycleparking
        bicycleparkingLabel
        bicyclerent
        bicyclerentLabel
        ebikerent
        ebikerentLabel
        eggs
        eggsLabel
        green
        greenLabel
        headline
        organic
        organicLabel
        shuttlebus
        shuttlebusLabel
      }
      energy {
        charging
        chargingLabel
        generaterenewable
        generaterenewableLabel
        greenhouse
        greenhouseLabel
        grows
        growsLabel
        guest
        guestLabel
        headline
        insulation
        insulationLabel
        led
        ledLabel
        localfood
        localfoodLabel
        management
        managementLabel
        nominibar
        nominibarLabel
        renewable
        renewableLabel
        sensors
        sensorsLabel
        vegan
        veganLabel
        vegetarian
        vegetarianLabel
        waste
        wasteLabel
        wasteenergy
        wasteenergyLabel
        windows
        windowsLabel
      }
      lastModifiedAt
      other {
        headline
        methods
        methodsLabel
        report
        reportLabel
      }
      waste{
        cosmetics
        cosmeticsLabel
        dispenser
        dispenserLabel
        foodwaste
        foodwasteLabel
        headline
        nochemical
        nochemicalLabel
        noplasticstirrers
        noplasticstirrersLabel
        noplasticstraws
        noplasticstrawsLabel
        nosinglebottle
        nosinglebottleLabel
        nosodabottle
        nosodabottleLabel
        recyclingplan
        recyclingplanLabel
        reusablecups
        reusablecupsLabel
        reusabletableware
        reusabletablewareLabel
        waterrefill
        waterrefillLabel  
      }
      water {
        garden
        gardenLabel
        headline
        laundry
        laundryLabel
        noroomcleaning
        noroomcleaningLabel
        pool
        poolLabel
        showers
        showersLabel
        toilets
        toiletsLabel
        towelreuse
        towelreuseLabel
        wastewater
        wastewaterLabel
      }
    }
  }
}`}
                                            </pre>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7">
                                getEntryRequirements
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve entry requirements for a specified country.</p>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getEntryRequirements(countryCode: "ES"){
    health
    country
    passportAndVisa
    money
  }
}`}
                                            </pre>
                                        </div>
                                        <h4>Full Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
\tgetEntryRequirements(countryCode: "ES"){
    leadIn
    currentSituation
    security
    nature
    travelInformation
    customsDuty
    health
    country
    other
    healthcare
    passportAndVisa
    money
    dutyfree
    disclaimers {
        source
        title
        edition
        link
        type
        description
    }
  }
}`}
                                            </pre>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="9">
                                getCountry
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="9">
                                <Card.Body>
                                    <div>
                                        <p>This query allows you to retrieve information for all countries.</p>

                                        <h4>Example</h4>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getCountries{
    name
    alpha2Code
    entryRequirements {
      passportAndVisa
    }
  }
}`}
                                            </pre>
                                        </div>
                                        <p>The Full Example shows you all the possibilities. Just remove the entries you do not need to customize to the request you need.</p>
                                        <h4>Full Example</h4>
                                        <p><b>This example queries a lot of data, so you probably will get a server error.</b></p>
                                        <div className="code-wrapper">
                                            <pre className="pre-code">
                                                {`{
  getCountries{
    alpha3Code
    alpha2Code
    name
    entryRequirements {
      leadIn
      currentSituation
      security
      nature
      travelInformation
      customsDuty
      health
      country
      other
      healthcare
      passportAndVisa
      money
      dutyfree
      disclaimers {
        source
        title
        edition
        link
        type
        description
      }
    }
    coronaOpeningDates {
      foreignOffice
      tourOperator
      additionalInfo
    }
  }
}`}
                                            </pre>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>

                <div className="documentation-item">
                    <h1>Exploring the full Content.API schema</h1>
                    <p>In the previous section ("Available queries") we already showed you the parameters you can pass to your Content.API GraphQL queries.
                        But of course you also want to know all the possible values of these parameters, especially if it is an ENUM-Type like the locales.
                        The <a href="https://prod.api.tui/content"  >Content.API-Playground</a> provides several ways of how to explore the schema:</p>

                    <ul>
                        <li><b>Using the real-time autocomplete feature:</b> (press CTRL + Space to open the autocomplete list)<br></br><img className="small-image" src={autocomplete} alt="Autocomplete feature" /></li>
                        <li><b>Using the integrated Documentation:</b> (press button "DOCS" at the right side of the playground)<br></br><img className="small-image" src={documentation} alt="Explore documentation" /></li>
                        <li><b>Using the integrated Schema-Definition:</b> (press button "SCHEMA" at the right side of the playground)<br></br><img className="small-image" src={schema} alt="Explore schema" /></li>
                    </ul>
                </div>

                <div className="documentation-item">
                    <h1>Consuming GraphQL</h1>
                    <p>Of course you don´t just want to explore the content by using the playground. You want to use the Content.API with your services. To do so, this section provides some usefull information about how to use GraphQL.</p>

                    <h2>Calling GraphQL with Apollo-Client</h2>
                    <p>GraphQL can best be consumed using the Apollo-Client for JavaScript. A great tutorial for that can be found <a href="https://www.apollographql.com/docs/tutorial/client/"  >here</a>.</p>

                    <h2>Calling GraphQL by REST</h2>
                    <p className="list-intro">Calling GraphQL by REST requires a POST operation and a JSON body with two parameters</p>
                    <ul>
                        <li>query: A string containing the query. Variable values are prefixed with a '$'</li>
                        <li>variables: A complex JSON object containing the variable values of your query</li>
                    </ul>

                    <p className="list-intro">To create your desired query we recommend the following procedure</p>
                    <ul>
                        <li>Specify the query using the GraphQL-Playground</li>
                        <li>Within the playground, click "COPY CURL"</li>
                        <li>Replace the dynamic fields from your query with a variable name (variable name prefixed with '$')</li>
                    </ul>

                    <h4>Example for plain JavaScript</h4>
                    <div className="code-wrapper">
                        <pre className="pre-code">{javascriptSnippet}</pre>
                    </div>

                    <br />
                    <h4>Example for Java</h4>
                    <div className="code-wrapper">
                        <pre className="pre-code">{javaSnippet}</pre>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DocumentationGraphQL;
