import React from 'react';
import './Changelog.css';

function Changelog() {
    return (
        <div className="changelog">
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">30.01.2024</span>
                </div>
                <div className="item-content">
                    <ul>
                        <li>Add language field to getHotelByCode full example</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">22.01.2024</span>
                </div>
                <div className="item-content">
                    <ul>
                        <li>Remove beta section</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">21.12.2023</span>
                </div>
                <div className="item-content">
                    <ul>
                        <li>Add raw attributes: Locations distances</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">27.11.2023</span>
                </div>
                <div className="item-content">
                    <ul>
                        <li>Add raw attributes: Locations details for 'altitude of the city/village above sea level', 'length of the island', 'width of the island'</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">10.10.2023</span>
                </div>
                <div className="item-content">
                    <ul>
                        <li>Add raw attributes: Room attributes</li>
                        <li>Add raw attributes: Locations details</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">13.06.2023</span>
                </div>
                <div className="item-content">
                    <ul>
                        <li>Add raw attributes: Check In / Check Out</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">19.03.2023</span>
                </div>
                <div className="item-content">
                    <ul>
                        <li>Add season information ('winter') in images</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">01.03.2023</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>CR Content API no longer relies on Apollo Studio.</li>
                        <li>Add sustainability information for Hotel's</li>
                    </ul>
                </div>
            </div>
            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">24.06.2020</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>Add entry-requirements for hotels (located in new "Country" entity)</li>
                        <li>Fixed CORS issues with Apigee Production-Endpoint</li>
                        <li>Simplified endpoints (from https://prod.api.tui/content/api to https://prod.api.tui/content). But of course the old endpoints will still work</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="date">10.06.2020</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>Introduced Apollo Federation as Content.API gateway (and therefore removed version from consolidated changelog)</li>
                        <li>Add hygiene information for Hotel's</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="version">Version: 2020.11.0</span>
                    <span className="date">06.05.2020</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>Support for mulit-hotels query vie GraphQL (getHotelsByCodes, getHotelsByGiataIds, getHotelsByCmdIds)</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="version">Version: 2020.6.4</span>
                    <span className="date">12.02.2020</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>Apigee endpoints for prod environment</li>
                        <li>promotion related product-information, like 'TUI Plus Package included'</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="version">Version: 2020.2.2</span>
                    <span className="date">28.01.2020</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>new attribute "cobrand" including the cobrand icon and name</li>
                        <li>new attributes "facilities" and "facilityDesc" for rooms (ATM limited to Destimo Hotels only)</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="version">Version: 2020.0.2</span>
                    <span className="date">12.12.2019</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>Multi-language support (german, english, spanish, french and portuguese) for many hotels</li>
                        <li>Revised GraphQL Model-Schema (e.g. the address, phone, fax etc. can now be found under contact). Old attributes have been marked as deprecated</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="version">Version: 2019.49.0</span>
                    <span className="date">18.11.2019</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>GlobalTypes for hotels and rooms</li>
                        <li>REST-Endpoints in test environment</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="version">Version: 2019.42.1</span>
                    <span className="date">17.10.2019</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>awards and labels</li>
                        <li>new locale 'de_DE' - marked old locale 'de' as deprecated</li>
                    </ul>
                </div>
            </div>

            <div className="changelog-item">
                <div className="item-header">
                    <span className="version">Version: 2019.40.30</span>
                    <span className="date">30.09.2019</span>
                </div>

                <div className="item-content">
                    <ul>
                        <li>new query "getHotelByCmdId"</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Changelog;
