import React from "react";
import "./Impressum.css";

function Impressum() {
    return (
        <div className="impressum">
            <h1>Impressum</h1>
            <p>
                TUI Deutschland GmbH<br />
                Karl-Wiechert-Allee 23<br />
                30625 Hannover<br />
                DEUTSCHLAND
            </p>
            <p>
                Telefon: +49 (0) 511 - 567 0<br />
                Telefax: +49 (0) 511 - 567 1301
            </p>
            <p>
                E-Mail: <a href="mailto:info@tui.info">info@tui.info</a><br />
                Internet: <a href="https://prod.api.tui/content">https://prod.api.tui/content</a><br />
                E-Mail-Anfragen werden i.d.R. innerhalb von 24h beantwortet.
            </p>
            <p>
                <strong>Vorsitzender des Aufsichtsrates</strong><br />Mathias Kiep
            </p>
            <p>
                <strong>Vertretungsberechtigte Geschäftsführer</strong><br />Stefan Baumert (Vorsitzender), Dr. Susanne Gauglitz, Benjamin Jacobi
            </p>
            <p>
                <strong>Registergericht</strong><br />
                Amtsgericht Hannover
            </p>
            <p>
                <strong>Registernummer</strong><br />
                HRB 62522
            </p>
            <p>
                <strong>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz</strong><br />
                DE 242380569
            </p>
            <p>
                <strong>Verbraucherstreitbeilegung / OS-Plattform</strong>
            </p>
            <p>
                TUI Deutschland GmbH nimmt derzeit nicht an einem – für sie freiwilligen – Verfahren zur alternativen
                Streitbeilegung nach dem Verbraucherstreitbeilegungsgesetz teil. Daher kann ein solches Verfahren und
                auch die von der EU Kommission unter <a
                href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a> bereitgestellte
                Plattform zur Online-Beilegung verbraucherrechtlicher Streitigkeiten (OS-Plattform) von unseren Kunden
                nicht genutzt werden.
            </p>
        </div>
    );
}

export default Impressum;
